// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  // Initialize tooltips
  [].slice
    .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

  // Submit on page component change
  $(".per-page").on("change", function () {
    $(this).closest("form").submit();
  });

  $('select[name="top-menu-roles"]').on("change", function () {
    $.ajax({
      method: "PUT",
      url: "/change_current_role",
      data: { id: $(this).val() },
      dataType: "json",
    }).done(function (result) {
      location.href = "/";
    });
  });
});
