document.addEventListener('turbolinks:load', () => {

  $('#key_incidents_customer_id').on('change', function() {
    customerID = $('#key_incidents_customer_id').val()

    if (customerID) {
      $('#q_season_id_eq').val('')
      window.filter_form.showSeasons(customerID);
      $('#q_fixture_id_eq').val('')
    } else {
      $('#q_season_id_eq').val('')
      $('#q_fixture_id_eq').val('')
    }
  });

  $('.key_incident_season_id_eq').on('change', function() {
    seasonID = $('.key_incident_season_id_eq').val()

    if (seasonID) {
      window.filter_form.showFixturesBySeason(seasonID);
    }
  });
});



