// Initialize necessaries behaviours
document.addEventListener('turbolinks:load', () => {
  // On change customer select on user form
  $('#user_customer_id').on('change', function () {
    var customerId = $(this).val();
    var fieldsetUserLevels = $('fieldset.user_levels');

    if (customerId) {
      $.ajax({
        method: 'PUT',
        url: '/users/update_levels',
        data: { customer_id: customerId },
        dataType: 'json',
      }).done(function (result) {
        fieldsetUserLevels.find('div.form-check').remove();

        $.each(result.levels, function (index, level) {
          fieldsetUserLevels.append(
            `<div class="form-check"><input class="form-check-input check_boxes required" required="required" aria-required="true" type="checkbox" value="${level.id}" name="user[level_ids][]" id="user_level_ids_${level.id}"><label class="form-check-label collection_check_boxes" for="user_level_ids_${level.id}">${level.name}</label></div>`
          );
        });
      });
    } else {
      fieldsetUserLevels.find('div.form-check').remove();
    }
  });

  // Used on filter_form
  $('#user_customer_id_eq').on('change', function() {
    var customerId = $(this).val();

    if (customerId) {
      window.filter_form.showLevels(customerId, false);
    }
  });
});
