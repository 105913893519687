import Chart from "chart.js/auto";

// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  (Chart.defaults.font.family =
    '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'),
    (Chart.defaults.color = "#292b2c");

  if ($("#myAreaChart").length > 0) {
    new Chart(document.getElementById("myAreaChart"), {
      type: "line",
      data: {
        labels: ["", "", "", "", "", "", ""],
        datasets: [
          {
            data: [0, 17.5, 20, 21, 19, 18, 18.5],
            label: "-",
            borderColor: "#1B9D86",
            backgroundColor: "#1B9D86",
            fill: false,
          },
          {
            data: [0, 40.5, 21, 3, 6, 19, 38.3],
            label: "- ",
            borderColor: "#ffd700",
            backgroundColor: "#ffd700",
            fill: false,
          },
        ],
      },
      options: {
        title: {
          display: false,
          text: "World population per region (in millions)",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Months",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Body Composition",
              },
            },
          ],
        },
        legend: {
          display: false,
          position: "top",
          fullWidth: true,
          reverse: false,
        },
      },
    });
  }

  if ($("#myAreaChart2").length > 0) {
    new Chart(document.getElementById("myAreaChart2"), {
      type: "line",
      data: {
        labels: ["", "", "", "", "", "", ""],
        datasets: [
          {
            data: [0, 17.5, 20, 21, 19, 18, 18.5],
            label: "-",
            borderColor: "#1B9D86",
            backgroundColor: "#1B9D86",
            fill: false,
          },
          {
            data: [0, 40.5, 21, 3, 6, 19, 38.3],
            label: "- ",
            borderColor: "#ffd700",
            backgroundColor: "#ffd700",
            fill: false,
          },
        ],
      },
      options: {
        title: {
          display: false,
          text: "World population per region (in millions)",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Months",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Body Composition",
              },
            },
          ],
        },
        legend: {
          display: false,
          position: "top",
          fullWidth: true,
          reverse: false,
        },
      },
    });
  }

  if ($("#myBarChart").length > 0 && $("#myPieChart").length > 0) {
    (ctx = document.getElementById("myBarChart")),
      (myLineChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["January", "February", "March", "April", "May", "June"],
          datasets: [
            {
              label: "Revenue",
              backgroundColor: "rgba(2,117,216,1)",
              borderColor: "rgba(2,117,216,1)",
              data: [4215, 5312, 6251, 7841, 9821, 14984],
            },
          ],
        },
        options: {
          scales: {
            xAxes: [
              {
                time: {
                  unit: "month",
                },
                gridLines: {
                  display: !1,
                },
                ticks: {
                  maxTicksLimit: 6,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 15e3,
                  maxTicksLimit: 5,
                },
                gridLines: {
                  display: !0,
                },
              },
            ],
          },
          legend: {
            display: !1,
          },
        },
      })),
      (ctx = document.getElementById("myPieChart")),
      (myPieChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["Blue", "Red", "Yellow", "Green"],
          datasets: [
            {
              data: [12.21, 15.58, 11.25, 8.32],
              backgroundColor: ["#007bff", "#dc3545", "#ffc107", "#28a745"],
            },
          ],
        },
      }));
  }
});
