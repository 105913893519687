import flatpickr from "flatpickr";

document.addEventListener('turbolinks:load', () => {
  const dateInputs = document.querySelectorAll("input[type='date']");

  dateInputs.forEach((input) => {
    input.setAttribute("type", "text");

    flatpickr(input, {
      dateFormat: "Y-m-d",
    });
  });
});