document.addEventListener('turbolinks:load', () => {
  $('#q_customer_id_eq').on('change', function() {
    customerID = $('#q_customer_id_eq').val()

    if (customerID) {
      window.filter_form.showCompetitions(customerID);
      window.filter_form.showLevels(customerID, true);
      window.filter_form.showSeasons(customerID);
    }
  });
});

