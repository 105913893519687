import gamevideojs from "./gamevideojs";
import Url from "domurl";
import "videojs-thumbnail-sprite";

export default class AppointmentPlayer {
  constructor(options = {}) {
    // Player
    this.player = null;
    this.editTime = false;

    this.options = Object.assign(
      {
        //customize and force custom options
      },
      options
    );

    // Times
    this.currentTime = 0;
    this.currentStartTime = 0;
    this.currentEndTime = 0;
    this.incrementDecrement = 1;
    this.incrementDecrementMinusPlus = 15;

    // Define elements
    this.defineElements();

    // Initialize a new gamevideojs
    if ($("#appointment-player").length > 0) {
      this.player = gamevideojs("#appointment-player", this.options);

      // Added video sprites/frames images
      let frames = $("#appointment-player").data("frames");
      let sprites = []
      let frameStart = 0;

      frames.forEach(frame => {
        sprites.push({
          url: frame,
          start: frameStart,
          duration: 320,
          width: 160,
          height: 90,
          interval: 2,
        });
  
        frameStart = frameStart + 320;
      });

      this.player.thumbnailSprite({
        sprites: sprites
      });

      // Update player time
      this.player.on("timeupdate", () => {
        this.updateTimes();
      });
    }
  }

  defineElements() {
    // Spans
    this.observationEventTime = $(".observation-event-time");
    this.observationEventStartTime = $(".observation-event-start-time");
    this.observationEventEndTime = $(".observation-event-end-time");

    // Fields
    this.observationEventTimeField = $(".observation_event_time");
    this.observationEventStartTimeField = $(".observation_event_start_time");
    this.observationEventEndTimeField = $(".observation_event_end_time");

    // Edit times
    this.observationEditEventTimeField = $(".observation_edit_event_time");
    this.observationEditEventStartTimeField = $(
      ".observation_edit_event_start_time"
    );
    this.observationEditEventEndTimeField = $(
      ".observation_edit_event_end_time"
    );

    // Actions buttons minus and plus
    this.btnMinusEventTime = $(".btn-minus-event-time");
    this.btnPlusEventTime = $(".btn-plus-event-time");
    this.btnMinusEventStartTime = $(".btn-minus-event-start-time");
    this.btnPlusEventStartTime = $(".btn-plus-event-start-time");
    this.btnMinusEventEndTime = $(".btn-minus-event-end-time");
    this.btnPlusEventEndTime = $(".btn-plus-event-end-time");
    this.btnCancelKmi = $(".btn-cancel-kmi");

    // Camera angle links
    this.cameraAngleLinks = $(".camera-angle-link");

    // Cancel btns
    this.btnCancelCompetency = $("#btn-cancel-competency");
    this.btnCancelKeyIncident = $("#btn-cancel-kmi");
    this.btnCancelKeyIncidentCaution = $("#btn-cancel-kmi-caution");

    // Add behaviours
    this.btnBehaviours();
  }

  btnBehaviours() {
    let scope = this;

    this.btnMinusEventTime.on("click", function (event) {
      scope.clickBtnTimer(this, event);
    });

    this.btnPlusEventTime.on("click", function (event) {
      scope.clickBtnTimer(this, event);
    });

    this.btnMinusEventStartTime.on("click", function (event) {
      scope.clickBtnTimer(this, event);
    });

    this.btnPlusEventStartTime.on("click", function (event) {
      scope.clickBtnTimer(this, event);
    });

    this.btnMinusEventEndTime.on("click", function (event) {
      scope.clickBtnTimer(this, event);
    });

    this.btnPlusEventEndTime.on("click", function (event) {
      scope.clickBtnTimer(this, event);
    });

    this.btnCancelCompetency.on("click", function (event) {
      event.preventDefault();
      $("#flush-headingOne").find("button").click();
    });

    this.btnCancelKeyIncident.on("click", function (event) {
      event.preventDefault();
      $("#flush-headingFour").find("button").click();
    });

    this.btnCancelKeyIncidentCaution.on("click", function (event) {
      event.preventDefault();
      $("#flush-headingFive").find("button").click();
    });
  }

  clickBtnTimer(element, event) {
    event.preventDefault();
    this.editTime = true;

    this.pausePlayer();

    let action = $(element).data("action");
    let updateField = $($(element).data("update-field"));
    let updateView = $($(element).data("update-view"));
    let type = $(element).data("type");

    if (action == "minus") {
      this.decrementTimer(type, updateField, updateView);
    } else {
      this.incrementTimer(type, updateField, updateView);
    }
  }

  pausePlayer() {
    if (this.player) {
      this.player.pause();
    }
  }

  dispose() {
    if (this.player != null) {
      this.player.dispose();
    }
  }

  incrementTimer(type, updateField, updateView) {
    this.incrementOrDecrementTimer("plus", type, updateField, updateView);
  }

  decrementTimer(type, updateField, updateView) {
    this.incrementOrDecrementTimer("minus", type, updateField, updateView);
  }

  incrementOrDecrementTimer(action, type, updateField, updateView) {
    let currentVal = parseFloat(updateField.val());
    let newValue = currentVal;
    let increment_action =
      type == "time"
        ? this.incrementDecrement
        : this.incrementDecrementMinusPlus;

    if (action == "minus") {
      newValue = currentVal - increment_action;
    } else {
      newValue = currentVal + increment_action;
    }

    if (newValue < 0) {
      newValue = 0;
    }

    if (type == "time") {
      this.currentTime = newValue;

      // Start time
      if (this.currentStartTime >= this.currentTime) {
        this.currentStartTime =
          this.currentTime - this.incrementDecrementMinusPlus;

        this.updateTimerWithValue(
          this.currentStartTime,
          this.observationEventStartTimeField,
          this.observationEventStartTime
        );
      }

      // End time
      if (this.currentEndTime <= this.currentTime) {
        this.currentEndTime =
          this.currentTime + this.incrementDecrementMinusPlus;

        this.updateTimerWithValue(
          this.currentEndTime,
          this.observationEventEndTimeField,
          this.observationEventEndTime
        );
      }
    } else if (type == "start") {
      this.currentStartTime = newValue;

      // Current time
      if (this.currentStartTime >= this.currentTime) {
        this.currentTime =
          this.currentStartTime + this.incrementDecrementMinusPlus;
        this.updateTimerWithValue(
          this.currentTime,
          this.observationEventTimeField,
          this.observationEventTime
        );
      }

      // End time
      if (this.currentEndTime <= this.currentStartTime) {
        this.currentEndTime =
          this.currentTime + this.incrementDecrementMinusPlus;

        this.updateTimerWithValue(
          this.currentEndTime,
          this.observationEventEndTimeField,
          this.observationEventEndTime
        );
      }

      // End time
      if (this.currentTime == this.currentEndTime) {
        this.currentEndTime =
          this.currentTime + this.incrementDecrementMinusPlus;

        this.updateTimerWithValue(
          this.currentEndTime,
          this.observationEventEndTimeField,
          this.observationEventEndTime
        );
      }
    } else if (type == "end") {
      this.currentEndTime = newValue;

      // Current time
      if (this.currentEndTime == this.currentTime) {
        this.currentTime =
          this.currentEndTime - this.incrementDecrementMinusPlus;

        this.updateTimerWithValue(
          this.currentTime,
          this.observationEventTimeField,
          this.observationEventTime
        );
      }

      // Start time
      if (this.currentStartTime >= this.currentTime) {
        this.currentStartTime =
          this.currentTime - this.incrementDecrementMinusPlus;

        this.updateTimerWithValue(
          this.currentStartTime,
          this.observationEventStartTimeField,
          this.observationEventStartTime
        );
      }
    }

    if (this.currentTime < 0) {
      this.currentTime = 0;
    }

    if (this.currentStartTime < 0) {
      this.currentStartTime = 0;
    }

    if (this.currentEndTime < 0) {
      this.currentEndTime = 0;
    }

    if (newValue < 0) {
      newValue = 0;
    }

    this.player.currentTime(this.currentTime);
    this.updateTimerWithValue(newValue, updateField, updateView);
  }

  updateTimerWithValue(newValue, updateField, updateView) {
    let minutesAndSeconds = this.calcMinutesAndSeconds(newValue);
    // let formatedMinutesAndSeconds = this.formatHourMinute(
    //   minutesAndSeconds[0],
    //   minutesAndSeconds[1]
    // );
    updateField.val(newValue);
    updateView.html(
      this.player.gamevideojs.returnFormattedDataMatch(
        newValue
        //this.player.gamevideojs.getMatchTimeByRealTime(newValue)
      )
    );
  }

  updateTimes() {
    this.currentTime = this.player.currentTime();
    //this.currentTime = this.player.gamevideojs.getCurrentRealTime();

    if (!this.editTime) {
      this.currentStartTime =
        this.currentTime - this.incrementDecrementMinusPlus;
      this.currentEndTime = this.currentTime + this.incrementDecrementMinusPlus;
    }

    if (this.currentStartTime < 0) {
      this.currentStartTime = 0;
    }

    // Update divs
    this.observationEventTime.html(
      this.player.gamevideojs.returnFormattedDataMatch(
        this.currentTime
        //this.player.gamevideojs.getMatchTimeByRealTime(this.currentTime)
      )
    );
    this.observationEventStartTime.html(
      this.player.gamevideojs.returnFormattedDataMatch(
        this.currentStartTime
        //this.player.gamevideojs.getMatchTimeByRealTime(this.currentStartTime)
      )
    );
    this.observationEventEndTime.html(
      this.player.gamevideojs.returnFormattedDataMatch(
        this.currentEndTime
        //this.player.gamevideojs.getMatchTimeByRealTime(this.currentEndTime)
      )
    );

    // Update fields values
    this.observationEventTimeField.val(this.currentTime);
    this.observationEventStartTimeField.val(this.currentStartTime);
    this.observationEventEndTimeField.val(this.currentEndTime);

    // Update edit fields values
    this.observationEditEventTimeField.val(
      this.player.gamevideojs.returnFormattedDataMatch(this.currentTime)
    );
    this.observationEditEventStartTimeField.val(
      this.player.gamevideojs.returnFormattedDataMatch(this.currentStartTime)
    );
    this.observationEditEventEndTimeField.val(
      this.player.gamevideojs.returnFormattedDataMatch(this.currentEndTime)
    );

    // Update camera angle links
    this.updateCameraAngleLinks();
  }

  updateCameraAngleLinks() {
    let scope = this;

    this.cameraAngleLinks.each(function () {
      let href = $(this).attr("href");

      let url = new Url(href);
      url.query.event_start_time = scope.player.currentTime();

      $(this).attr("href", url.toString());
    });
  }

  calcMinutesAndSeconds(currentTime) {
    if (currentTime > 0) {
      let minutes = Math.floor(currentTime / 60);
      let seconds = Math.floor(currentTime - minutes * 60);

      return [minutes, seconds];
    } else {
      return [0, 0];
    }
  }

  formatHourMinute(minutes, seconds) {
    let x = minutes < 10 ? "0" + minutes : minutes;
    let y = seconds < 10 ? "0" + seconds : seconds;

    return x + ":" + y;
  }
}
