// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  $(".question-report-radio").on("click", function () {
    let label_type = $(this).data("label-type");
    let question_id = $(this).data("question-id");
    let comment_group = $(`#comment-group-${question_id}`);

    if (label_type != "na") {
      comment_group.removeClass("d-none");
    } else {
      comment_group.addClass("d-none");
    }
  });
});
