// Class that manages the place on map
export default class GmapsPlace {
  // Return route information from a place
  static route(place) {
    return GmapsPlace.getType("route", place);
  }

  // Return address information from a place
  static address(place) {
    return GmapsPlace.getType("address", place);
  }

  // Get address information by type
  static getType(type, place) {
    return GmapsPlace.extract(place)[type];
  }

  // Extract address information from place address_components
  static extract(place) {
    const info = {
      address: null,
      zipcode: null,
      state: null,
      city: null,
      number: null,
      neighborhood: null,
      country: null,
      lat: null,
      lng: null,
      route: null,
    };

    if (place.geometry) {
      if (place.formatted_address) {
        info["address"] = place.formatted_address;
        info["lat"] = place.geometry.location.lat();
        info["lng"] = place.geometry.location.lng();

        for (let i = 0; i < place.address_components.length; i++) {
          const component = place.address_components[i];

          switch (component.types[0]) {
            case "postal_code": // Set postal code
              info["zipcode"] = component.long_name;
              break;
            case "administrative_area_level_1": // Set state
              info["state"] = component.short_name;
              break;
            case "administrative_area_level_2": // Set city
              info["city"] = component.long_name;
              break;
            case "street_number": // Set number
              info["number"] = component.long_name;
              break;
            case "sublocality_level_1": // Set address_neighborhood
              info["neighborhood"] = component.long_name;
              break;
            case "country": // Set country
              info["country"] = component.short_name;
              break;
            case "locality": // Set city
              info["city"] = component.long_name;
              break;
            case "postal_code_prefix": // Set zipcode
              info["zipcode"] = component.long_name;
              break;
            case "political": // Set address_neighborhood
              info["neighborhood"] = component.long_name;
              break;
            case "route": // Set address
              info["route"] = component.long_name;
              break;
          }
        }
      }
    }

    return info;
  }
}
