// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  $('input:radio[name="fixture[overview_difficulty]"]').change(function () {
    $.ajax({
      method: "PUT",
      url: "/list_overview_referee_impact",
      data: { overview_difficulty: $(this).val() },
      dataType: "json",
    }).done(function (result) {
      $("#fixture_overview_referee_impact").find("option").remove();

      $("#fixture_overview_referee_impact").append(
        `<option value=""></option>`
      );
      $.each(result, function (index, item) {
        $("#fixture_overview_referee_impact").append(
          `<option value="${item[1]}">${item[0]}</option>`
        );
      });
    });
  });
});
