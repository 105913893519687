import videojs from "video.js";

const baseCssName = "vjs-stepRewind-and-stepForward-button";

const stepRewindForwardButton = function (options) {
  this.ready(() => {
    let buttons = {
      back: 1,
      forward: 1
    };

    options = videojs.mergeOptions(buttons, options);

    //Add button rewind in controlbar
    this.controlBar.rewindButton = this.controlBar.addChild(
      "stepRewindForwardButton",
      {
        typeBtn: "stepRewind",
        time: options.back,
      },
      0
    );

    //Add button forward in controlbar
    this.controlBar.forwardButton = this.controlBar.addChild(
      "stepRewindForwardButton",
      {
        typeBtn: "stepForward",
        time: options.forward,
      },
      4
    );
  });
};

class StepRewindForwardButton extends videojs.getComponent("Button") {
  constructor(player, options) {
    super(player, options);
  }

  buildCSSClass() {
    return (
      baseCssName +
      " " +
      baseCssName +
      "-" +
      this.options_.typeBtn +
      " " +
      super.buildCSSClass()
    );
  }

  handleClick() {
    const now = this.player_.currentTime();
    const currentPlayer = this;

    window.testea = currentPlayer.player_;

    if (this.options_.typeBtn === "stepForward") {
      if (
        this.player_.techName_ === "vimeo" ||
        this.player_.techName_ == "Vimeo"
      ) {
        try {
          this.player_.techGet_("vimeoGetCurrentTime").then(function (time) {
            currentPlayer.player_.techCall_(
              "vimeoSetCurrentTime",
              time + currentPlayer.options_.time
            );
          });
        } catch (error) {}
      } else {
        this.player_.currentTime(now + this.options_.time);
      }
    }

    if (this.options_.typeBtn === "stepRewind") {
      if (
        this.player_.techName_ === "vimeo" ||
        this.player_.techName_ == "Vimeo"
      ) {
        try {
          this.player_.techGet_("vimeoGetCurrentTime").then(function (time) {
            currentPlayer.player_.techCall_(
              "vimeoSetCurrentTime",
              time - currentPlayer.options_.time
            );
          });
        } catch (error) {}
      } else {
        this.player_.currentTime(now - this.options_.time);
      }
    }
  }
}
videojs.registerComponent("StepRewindForwardButton", StepRewindForwardButton);
videojs.registerPlugin("stepRewindForwardButton", stepRewindForwardButton);

export default stepRewindForwardButton;
