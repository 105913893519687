export default class Appointment {
  constructor() {
    $("#flush-headingOne").on("click", () => {
      window.appointment_player.player.pause();
    });

    $("#flush-headingFour").on("click", () => {
      window.appointment_player.player.pause();
    });

    $("#flush-headingFive").on("click", () => {
      window.appointment_player.player.pause();
    });
  }
}
