// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  jQuery(function ($) {
    $(document).on("click", "#run", function (e) {
      e.preventDefault();
      $("#simple-example-table").stacktable();
    });
    $("#responsive-table").stacktable({
      myClass: "table",
    });
    $("#responsive-table2").stacktable({
      myClass: "table",
    });
    $("#responsive-table3").stacktable({
      myClass: "table",
    });
    $("#responsive-table4").stacktable({
      myClass: "table",
    });
    $("#responsive-table5").stacktable({
      myClass: "table",
    });
    $("#responsive-table6").stacktable({
      myClass: "table",
    });
    $("#responsive-table7").stacktable({
      myClass: "table",
    });
  });

  jQuery(function ($) {
    $(".js-range-slider").ionRangeSlider({
      type: "double",
      min: 0,
      max: 99,
      from: 50,
      grid: false,
      type: "single",
      skin: "round",
    });
  });

  jQuery(function ($) {
    //offcanvas
    $("[data-toggle='offcanvas']").on("click", function () {
      $(".row-offcanvas").toggleClass("active");
    });
  });

  $("#open-ds").on("click", function () {
    if ($(".ds").css("display") !== "none") {
      $(".ds").css({
        display: "none",
      });
      $(".main-content-wrap").css({
        "min-width": "100%",
      });
      $(".ds-expand .fa-search").css({
        display: "block",
      });
      $(".ds-expand .fa-chevron-right").css({
        display: "none",
      });
    } else {
      $(".ds").css({
        display: "block",
      });

      $(".main-content-wrap").css({
        "min-width": "70%",
      });
      $(".ds-expand .fa-chevron-right").css({
        display: "block",
      });
      $(".ds-expand .fa-search").css({
        display: "none",
      });
    }
  });

  // Example starter JavaScript for disabling form submissions if there are invalid fields
  (function () {
    "use strict";
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  })();

  jQuery(function ($) {
    //toggle main nav img button
    $("#sidenavToggler").on("click", function () {
      $(".logo-toggle").toggleClass("hide-logo");
    });
  });

  jQuery(function ($) {
    //toggle main nav img button
    $("#open-ds-2").on("click", function () {
      $(".fa-ch").toggleClass("hides");
      $(".fa-se").toggleClass("hides");
    });
  });

  //Shake function for wrong login credentials
  jQuery(function ($) {
    $(".submits").on("click", function () {
      var $formContainer = $(".login-form");
      $formContainer.addClass("invalid");
      setTimeout(function () {
        $formContainer.removeClass("invalid");
      }, 500);
      $(".login-error").addClass("show");
    });
  });

  jQuery(function ($) {
    //toggle ticks for correct login credentials
    $(".correct-login-click").on("click", function () {
      $(".icons-group").addClass("icon-click");
    });
  });

  //Enables view-event-spaces-list.html hide show table function
  //Remove show-hide from table wraper divs and remove comments below to enable

  jQuery(document).ready(function ($) {
    $(".event-list-table-switch-1")
      .change(function () {
        $(".event-list-table-1").toggleClass("show-hide", this.checked);
      })
      .change();
  });

  jQuery(document).ready(function ($) {
    $(".event-list-table-switch-2")
      .change(function () {
        $(".event-list-table-2").toggleClass("show-hide", this.checked);
      })
      .change();
  });

  jQuery(document).ready(function ($) {
    $(".event-list-table-switch-3")
      .change(function () {
        $(".event-list-table-3").toggleClass("show-hide", this.checked);
      })
      .change();
  });

  jQuery(document).ready(function ($) {
    $(".event-list-table-switch-4")
      .change(function () {
        $(".event-list-table-4").toggleClass("show-hide", this.checked);
      })
      .change();
  });

  jQuery(document).ready(function ($) {
    $(".event-list-table-switch-5")
      .change(function () {
        $(".event-list-table-5").toggleClass("show-hide", this.checked);
      })
      .change();
  });

  jQuery(document).ready(function ($) {
    $(document).ready(function () {
      var date_input = $('input[name="date"]'); //our date input has the name "date"
      var container =
        $(".bootstrap-iso form").length > 0
          ? $(".bootstrap-iso form").parent()
          : "body";
      var options = {
        format: "dd/mm/yyyy",
        container: container,
        todayHighlight: true,
        autoclose: true,
      };
      date_input.datepicker(options);
    });
  });

  // Sticky navbar
  // =========================
  jQuery(function ($) {
    $(document).ready(function () {
      var windowWidth = $(window).width();
      if (windowWidth > 991) {
        // Custom function which toggles between sticky class (is-sticky)
        var stickyToggle = function (sticky, stickyWrapper, scrollElement) {
          var stickyHeight = sticky.outerHeight();
          var stickyTop = stickyWrapper.offset().top;
          if (scrollElement.scrollTop() >= stickyTop) {
            stickyWrapper.height(stickyHeight);
            sticky.addClass("is-sticky");
          } else {
            sticky.removeClass("is-sticky");
            stickyWrapper.height("auto");
          }
        };

        // Find all data-toggle="sticky-onscroll" elements
        $('[data-toggle="sticky-onscroll"]').each(function () {
          var sticky = $(this);
          var stickyWrapper = $("<div>").addClass("sticky-wrapper"); // insert hidden element to maintain actual top offset on page
          sticky.before(stickyWrapper);
          sticky.addClass("sticky");

          // Scroll & resize events
          $(window).on(
            "scroll.sticky-onscroll resize.sticky-onscroll",
            function () {
              stickyToggle(sticky, stickyWrapper, $(this));
            }
          );

          // On page load
          stickyToggle(sticky, stickyWrapper, $(window));
        });
      }
    });
  });

  jQuery(document).ready(function ($) {
    $(".clickable-row").click(function () {
      href = $(this).data("href");

      if ($(this).data("remote")) {
        $.ajax({ url: href });
      } else {
        Turbolinks.visit(href);
      }
    });

    $(".clickable-column").click(function () {
      href = $(this).data("href");

      if ($(this).data("remote")) {
        $.ajax({ url: href });
      } else {
        Turbolinks.visit(href);
      }
    });
  });

  jQuery(document).ready(function ($) {
    $(".observer-left-section").click(function () {
      //Turbolinks.visit($(this).data("href"));
    });
  });

  jQuery(document).ready(function ($) {
    function bs_input_file() {
      $(".input-file").before(function () {
        if (!$(this).prev().hasClass("input-ghost")) {
          var element = $(
            "<input type='file' class='input-ghost' style='visibility:hidden; height:0'>"
          );
          element.attr("name", $(this).attr("name"));
          element.change(function () {
            element
              .next(element)
              .find("input")
              .val(element.val().split("\\").pop());
          });
          $(this)
            .find("button.btn-choose")
            .click(function () {
              element.click();
            });
          $(this)
            .find("button.btn-reset")
            .click(function () {
              element.val(null);
              $(this).parents(".input-file").find("input").val("");
            });
          $(this).find("input").css("cursor", "pointer");
          $(this)
            .find("input")
            .mousedown(function () {
              $(this).parents(".input-file").prev().click();
              return false;
            });
          return element;
        }
      });
    }
    $(function () {
      bs_input_file();
    });
  });

  jQuery(function ($) {
    //modal slider
    $(".sidebar-click").on("click", function () {
      $(".new-section").toggleClass("hide-show-sidebar");
      $(".table-sidebar-hide").toggleClass("new-section");
      $(".hidden-details").toggleClass("show-details");
    });
  });

  jQuery(function ($) {
    //modal slider
    $(".sidebar-click-2").on("click", function () {
      $(".new-section-2").toggleClass("hide-show-sidebar");
      $(".table-sidebar-hide").toggleClass("new-section");
      $(".hidden-details").toggleClass("show-details");
    });
  });

  jQuery(function ($) {
    //modal slider
    $(".slider-arrow").on("click", function () {
      $(".modal-search1").toggleClass("hide-show-modal");
      $(".modal-search2").toggleClass("hide-show-modal");
    });
  });

  jQuery(function ($) {
    //row-add-color
    $(".add-hover-click-1").on("click", function () {
      $(".add-hover-1").toggleClass("table-row-hover");
    });
  });

  jQuery(function ($) {
    //row-add-color
    $(".add-hover-click-2").on("click", function () {
      $(".add-hover-2").toggleClass("table-row-hover");
    });
  });

  jQuery(function ($) {
    //row-add-color
    $(".add-hover-click-3").on("click", function () {
      $(".add-hover-3").toggleClass("table-row-hover");
    });
  });

  jQuery(function ($) {
    //row-add-color
    $(".add-hover-click-4").on("click", function () {
      $(".add-hover-4").toggleClass("table-row-hover");
    });
  });

  jQuery(function ($) {
    //row-add-color
    $(".add-hover-click-5").on("click", function () {
      $(".add-hover-5").toggleClass("table-row-hover");
    });
  });

  jQuery(function ($) {
    //row-add-color
    $(".add-hover-click-6").on("click", function () {
      $(".add-hover-6").toggleClass("table-row-hover");
    });
  });

  jQuery(function ($) {
    //display chart
    $(".card-analysis").on("click", function () {
      $(".chart-row").toggleClass("display-chart");
    });
  });

  jQuery(function ($) {
    //show chart pop up
    $(".chart-date").on("click", function () {
      $(".pop-up-chart").toggleClass("display-pop-up");
    });
  });
  jQuery(function ($) {
    //hide chart pop up
    $(".pop-up-chart-close").on("click", function () {
      $(".pop-up-chart").toggleClass("display-pop-up");
    });
  });

  jQuery(function ($) {
    if ($("#dash-canvas").length > 0) {
      new Chart(document.getElementById("dash-canvas"), {
        type: "line",
        data: {
          labels: [1, 2],
          datasets: [
            {
              data: [836, 2478],
              label: "Referees",
              borderColor: "#65B173",
              fill: false,
            },
            {
              data: [282, 5267],
              label: "Assistants",
              borderColor: "#C62922",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: false,
            text: "Chart.js Line Chart",
          },
          legend: {
            position: "bottom",
            labels: {
              fontColor: "#ffffff",
            },
          },
          tooltips: {
            display: false,
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                color: "#ffffff",
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                  zeroLineColor: "#ffffff",
                },
                display: true,
                ticks: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                  fontColor: "#ffffff",
                  labelString: "Rounds",
                },
              },
            ],
            yAxes: [
              {
                color: "#ffffff",
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                  zeroLineColor: "#ffffff",
                },
                display: true,
                ticks: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                  fontColor: "#ffffff",
                  labelString: "Technical Average",
                },
              },
            ],
          },
        },
      });
    }
  });
});
