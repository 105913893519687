export default class EditObservationCompetency {
  constructor() {
    // On change observation user id select on form
    $("#form-edit-observation-competency .field_edit_observation_competency_event_type_id").on("change", function () {
      var eventTypeId = $(this).find(":selected").val();
      var eventTypeTechnicalId = $("#form-edit-observation-competency .field_edit_observation_competency_event_type_technical_id");
      var eventTypeAnswerId = $("#form-edit-observation-competency .field_edit_observation_competency_event_type_answer_id");

      if (eventTypeId) {
        // Event Type Technicals
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);

        // Event Type Technicals
        $.ajax({
          method: "GET",
          url: "/event_type_technicals",
          data: { event_type_id: eventTypeId },
          dataType: "json",
        }).done(function (result) {
          eventTypeTechnicalId.find("option").remove();
          eventTypeTechnicalId.append(`<option value=""></option>`);

          if (result.length > 0) {
            eventTypeTechnicalId.parent().show();

            $.each(result, function (index, eventTypeTechnical) {
              eventTypeTechnicalId.append(
                `<option value="${eventTypeTechnical.id}">${eventTypeTechnical.name}</option>`
              );
            });
          } else {
            eventTypeTechnicalId.parent().hide();

            // Event Type Answers
            $.ajax({
              method: "GET",
              url: "/event_type_answers",
              data: { event_type_id: eventTypeId },
              dataType: "json",
            }).done(function (result) {
              eventTypeAnswerId.find("option").remove();
              eventTypeAnswerId.append(`<option value=""></option>`);

              $.each(result, function (index, eventTypeAnswer) {
                eventTypeAnswerId.append(
                  `<option value="${eventTypeAnswer.id}">${eventTypeAnswer.name}</option>`
                );
              });

              eventTypeAnswerId.prop("disabled", false);
            });
          }

          eventTypeTechnicalId.prop("disabled", false);
        });
      } else {
        // Event Type Technicals
        eventTypeTechnicalId.find("option").remove();
        eventTypeTechnicalId.prop("disabled", true);

        // Event Type Answers
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);
      }
    });

    // On change observation user id select on form
    $("#form-edit-observation-competency .field_edit_observation_competency_event_type_technical_id").on("change", function () {
      var eventTypeTechnicalId = $(this).find(":selected").val();
      var eventTypeAnswerId = $("#form-edit-observation-competency .field_edit_observation_competency_event_type_answer_id");

      if (eventTypeTechnicalId) {
        // Event Type Technicals
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);

        // Event Type Answers
        $.ajax({
          method: "GET",
          url: "/event_type_answers",
          data: { event_type_technical_id: eventTypeTechnicalId },
          dataType: "json",
        }).done(function (result) {
          eventTypeAnswerId.find("option").remove();
          eventTypeAnswerId.append(`<option value=""></option>`);

          $.each(result, function (index, eventTypeAnswer) {
            eventTypeAnswerId.append(
              `<option value="${eventTypeAnswer.id}">${eventTypeAnswer.name}</option>`
            );
          });

          eventTypeAnswerId.prop("disabled", false);
        });
      } else {
        // Event Type Answers
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);
      }
    });
  }
}
