import videojs from "video.js";

const baseCssName = "vjs-rewind-and-forward-button";

const rewindForwardButton = function (options) {
  this.ready(() => {
    let buttons = {
      rewind: 1,
      foward: 1
    };

    options = videojs.mergeOptions(buttons, options);

    //Add button rewind in controlbar
    this.controlBar.rewindButton = this.controlBar.addChild(
      "rewindForwardButton",
      {
        typeBtn: "rewind",
        time: options.forward,
      },
      0
    );

    //Add button forward in controlbar
    this.controlBar.forwardButton = this.controlBar.addChild(
      "rewindForwardButton",
      {
        typeBtn: "forward",
        time: options.forward,
      },
      2
    );
  });
};

class RewindForwardButton extends videojs.getComponent("Button") {
  constructor(player, options) {
    super(player, options);
  }

  buildCSSClass() {
    return (
      baseCssName +
      " " +
      baseCssName +
      "-" +
      this.options_.typeBtn +
      " " +
      super.buildCSSClass()
    );
  }

  handleClick() {
    const now = this.player_.currentTime();
    const currentPlayer = this;

    window.testea = currentPlayer.player_;

    if (this.options_.typeBtn === "forward") {
      if (
        this.player_.techName_ === "vimeo" ||
        this.player_.techName_ == "Vimeo"
      ) {
        try {
          this.player_.techGet_("vimeoGetCurrentTime").then(function (time) {
            currentPlayer.player_.techCall_(
              "vimeoSetCurrentTime",
              time + currentPlayer.options_.time
            );
          });
        } catch (error) {}
      } else {
        this.player_.currentTime(now + this.options_.time);
      }
    }

    if (this.options_.typeBtn === "rewind") {
      if (
        this.player_.techName_ === "vimeo" ||
        this.player_.techName_ == "Vimeo"
      ) {
        try {
          this.player_.techGet_("vimeoGetCurrentTime").then(function (time) {
            currentPlayer.player_.techCall_(
              "vimeoSetCurrentTime",
              time - currentPlayer.options_.time
            );
          });
        } catch (error) {}
      } else {
        this.player_.currentTime(now - this.options_.time);
      }
    }
  }
}
videojs.registerComponent("RewindForwardButton", RewindForwardButton);
videojs.registerPlugin("rewindForwardButton", rewindForwardButton);

export default rewindForwardButton;
