export default class Observation {
  constructor() {
    // On change observation user id select on form
    $(".field_observation_user_id").on("change", function () {
      var userType = $(this).find(":selected").data("user-type");
      var eventCategoryId = $("#flush-collapseFour #observation_event_category_id");
      var eventCategoryCautionId = $("#flush-collapseFive #observation_event_category_id");
      var eventTypeId = $(".field_observation_event_type_id");
      var eventTypeTechnicalId = $("#observation_event_type_technical_id");
      var eventTypeAnswerId = $("#observation_event_type_answer_id");

      if (userType) {
        // Event Type Technicals
        eventTypeTechnicalId.find("option").remove();
        eventTypeTechnicalId.prop("disabled", true);

        // Event Type Technicals
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);  

        // Categories
        $.ajax({
          method: "GET",
          url: "/event_categories",
          data: { user_type: userType },
          dataType: "json",
        }).done(function (result) {
          eventCategoryId.find("option").remove();
          eventCategoryId.append(`<option value=""></option>`);

          $.each(result, function (index, eventCategory) {
            eventCategoryId.append(
              `<option value="${eventCategory.id}">${eventCategory.name}</option>`
            );
          });

          eventCategoryId.prop("disabled", false);
        });

        // Categories
        $.ajax({
          method: "GET",
          url: "/event_categories/cautions",
          data: { user_type: userType },
          dataType: "json",
        }).done(function (result) {
          eventCategoryCautionId.find("option").remove();
          eventCategoryCautionId.append(`<option value=""></option>`);

          $.each(result, function (index, eventCategory) {
            eventCategoryCautionId.append(
              `<option value="${eventCategory.id}">${eventCategory.name}</option>`
            );
          });

          eventCategoryCautionId.prop("disabled", false);
        });

        // Types
        $.ajax({
          method: "GET",
          url: "/event_types",
          data: { user_type: userType },
          dataType: "json",
        }).done(function (result) {
          eventTypeId.find("option").remove();
          eventTypeId.append(`<option value=""></option>`);

          $.each(result, function (index, eventType) {
            eventTypeId.append(
              `<option value="${eventType.id}">${eventType.name}</option>`
            );
          });

          eventTypeId.prop("disabled", false);
        });
      } else {
        // Categories
        eventCategoryId.find("option").remove();
        eventCategoryId.prop("disabled", true);

        //categories
        eventCategoryCautionId.find("option").remove();
        eventCategoryCautionId.prop("disabled", true);

        // Types
        eventTypeId.find("option").remove();
        eventTypeId.prop("disabled", true);

        // Event Type Technicals
        eventTypeTechnicalId.find("option").remove();
        eventTypeTechnicalId.prop("disabled", true);

        // Event Type Technicals
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);
      }
    });

    // On change observation user id select on form
    $(".field_observation_event_type_id").on("change", function () {
      var eventTypeId = $(this).find(":selected").val();
      var eventTypeTechnicalId = $("#observation_event_type_technical_id");
      var eventTypeAnswerId = $("#observation_event_type_answer_id");

      if (eventTypeId) {
        // Event Type Technicals
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);

        // Event Type Technicals
        $.ajax({
          method: "GET",
          url: "/event_type_technicals",
          data: { event_type_id: eventTypeId },
          dataType: "json",
        }).done(function (result) {
          eventTypeTechnicalId.find("option").remove();
          eventTypeTechnicalId.append(`<option value=""></option>`);

          if (result.length > 0) {
            eventTypeTechnicalId.parent().show();

            $.each(result, function (index, eventTypeTechnical) {
              eventTypeTechnicalId.append(
                `<option value="${eventTypeTechnical.id}">${eventTypeTechnical.name}</option>`
              );
            });
          } else {
            eventTypeTechnicalId.parent().hide();

            // Event Type Answers
            $.ajax({
              method: "GET",
              url: "/event_type_answers",
              data: { event_type_id: eventTypeId },
              dataType: "json",
            }).done(function (result) {
              eventTypeAnswerId.find("option").remove();
              eventTypeAnswerId.append(`<option value=""></option>`);

              $.each(result, function (index, eventTypeAnswer) {
                eventTypeAnswerId.append(
                  `<option value="${eventTypeAnswer.id}">${eventTypeAnswer.name}</option>`
                );
              });

              eventTypeAnswerId.prop("disabled", false);
            });
          }

          eventTypeTechnicalId.prop("disabled", false);
        });
      } else {
        // Event Type Technicals
        eventTypeTechnicalId.find("option").remove();
        eventTypeTechnicalId.prop("disabled", true);

        // Event Type Answers
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);
      }
    });

    // On change observation user id select on form
    $("#observation_event_type_technical_id").on("change", function () {
      var eventTypeTechnicalId = $(this).find(":selected").val();
      var eventTypeAnswerId = $("#observation_event_type_answer_id");

      if (eventTypeTechnicalId) {
        // Event Type Technicals
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);

        // Event Type Answers
        $.ajax({
          method: "GET",
          url: "/event_type_answers",
          data: { event_type_technical_id: eventTypeTechnicalId },
          dataType: "json",
        }).done(function (result) {
          eventTypeAnswerId.find("option").remove();
          eventTypeAnswerId.append(`<option value=""></option>`);

          $.each(result, function (index, eventTypeAnswer) {
            eventTypeAnswerId.append(
              `<option value="${eventTypeAnswer.id}">${eventTypeAnswer.name}</option>`
            );
          });

          eventTypeAnswerId.prop("disabled", false);
        });
      } else {
        // Event Type Answers
        eventTypeAnswerId.find("option").remove();
        eventTypeAnswerId.prop("disabled", true);
      }
    });
  }
}
