// Class that manages the users behaviours
export default class User {
  // Class config variables
  address_street_id = "user_address_street";
  lat_id = "#user_lat";
  lng_id = "#user_lng";
  zipcode_id = "#user_zipcode";
  address_state_id = "#user_address_state";
  address_city_id = "#user_address_city";
  address_number_id = "#user_address_number";
  address_neighborhood_id = "#user_address_neighborhood";
  country_id = "#user_country";

  // Constructor class get the dom element and add necessary behaviours
  constructor() {
    this.input = document.getElementById(this.address_street_id);

    if (this.input) {
      this.addListener();
    }
  }

  // Set default options
  getOptions() {
    this.options = {
      type: ["address"],
      language: window.locale,
    };
    return this.options;
  }

  // Add listener behaviour on input field
  // when user type search for address on Google maps api
  addListener() {
    const scope = this;
    const autocomplete = new google.maps.places.Autocomplete(
      this.input,
      this.getOptions(),
    );
    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();
      scope.fillFormFields(place);
    });
  }

  // Clean data on fields before put a new data
  cleanFields() {
    $(this.lat_id).val("");
    $(this.lng_id).val("");
    $(this.zipcode_id).val("");
    $(this.address_state_id).val("");
    $(this.address_city_id).val("");
    $(this.address_number_id).val("");
    $(this.address_neighborhood_id).val("");
    $(this.country_id).val("");
    $(this.country_id).trigger("change");
  }

  // Fill form fields with place information
  fillFormFields(place) {
    // Clean fields for not use old data on forms
    this.cleanFields();

    // Get all address data from place
    const address_data = GmapsPlace.extract(place);

    // Fill address with formatted address
    $(this.address_street_id).val(address_data["address"]);

    // Fill lat and lng
    $(this.lat_id).val(address_data["lat"]);
    $(this.lng_id).val(address_data["lng"]);

    // Fill other fields
    $(this.zipcode_id).val(address_data["zipcode"]);
    $(this.address_state_id).val(address_data["state"]);
    $(this.address_city_id).val(address_data["city"]);
    $(this.address_number_id).val(address_data["number"]);
    $(this.address_neighborhood_id).val(address_data["neighborhood"]);
    $(this.country_id).val(address_data["country"]);
    $(this.country_id).trigger("change");
  }
}
