// Initialize necessaries behaviours
document.addEventListener('turbolinks:load', () => {
  $('#appointment_report_customer_id').on('change', function() {
    customerID = $('#appointment_report_customer_id').val()

    if (customerID) {
      window.filter_form.showCompetitions(customerID);
      window.filter_form.showSeasons(customerID);
    }
  });
});
