export default class UserFixture {
  constructor() {
    // Used users id
    this.usedUsersId = [];

    // Trigger fields
    this.fixtureRefereeIdField = $("#fixture_referee_id");
    this.assistantReferee1IdField = $("#fixture_assistant_referee_1_id");
    this.assistantReferee2IdField = $("#fixture_assistant_referee_2_id");
    this.fourthOfficialField = $("#fixture_fourth_official_id");
    this.videoObserverField = $("#fixture_video_observer_id");

    // Current used values
    this.currentUsedValues = {
      fixture_referee_id: this.fixtureRefereeIdField.val(),
      fixture_assistant_referee_1_id: this.assistantReferee1IdField.val(),
      fixture_assistant_referee_2_id: this.assistantReferee2IdField.val(),
      fixture_fourth_official_id: this.fourthOfficialField.val(),
      fixture_video_observer_id: this.videoObserverField.val(),
    };

    this.allFields = [
      this.fixtureRefereeIdField,
      this.assistantReferee1IdField,
      this.assistantReferee2IdField,
      this.fourthOfficialField,
      this.videoObserverField,
    ];

    this.addBehaviours();
    this.rebuildUsedUsers();
    this.enableSelectsOption();
    this.disableSelectsOption();
  }

  addBehaviours() {
    var scope = this;

    $.each(this.allFields, function (i, field) {
      field.on("change", () => {
        scope.currentUsedValues[field.attr("id")] = field.val();
        scope.rebuildUsedUsers();
        scope.enableSelectsOption();
        scope.disableSelectsOption();
      });
    });
  }

  rebuildUsedUsers() {
    this.usedUsersId = [];

    if (this.currentUsedValues["fixture_referee_id"]) {
      this.usedUsersId.push(this.currentUsedValues["fixture_referee_id"]);
    }

    if (this.currentUsedValues["fixture_assistant_referee_1_id"]) {
      this.usedUsersId.push(
        this.currentUsedValues["fixture_assistant_referee_1_id"]
      );
    }

    if (this.currentUsedValues["fixture_assistant_referee_2_id"]) {
      this.usedUsersId.push(
        this.currentUsedValues["fixture_assistant_referee_2_id"]
      );
    }

    if (this.currentUsedValues["fixture_fourth_official_id"]) {
      this.usedUsersId.push(
        this.currentUsedValues["fixture_fourth_official_id"]
      );
    }

    if (this.currentUsedValues["fixture_video_observer_id"]) {
      this.usedUsersId.push(
        this.currentUsedValues["fixture_video_observer_id"]
      );
    }
  }

  enableSelectsOption() {
    $.each(this.allFields, function (i, field) {
      field.find("option").removeAttr("disabled");
    });
  }

  disableSelectsOption() {
    var scope = this;

    $.each(this.allFields, function (i, field) {
      $.each(scope.usedUsersId, function (j, usedUserId) {
        field
          .find("option[value=" + usedUserId + "]")
          .attr("disabled", "disabled");
      });

      field.find("option:selected").removeAttr("disabled");
    });
  }
}
