document.addEventListener("turbolinks:load", () => {
  $(".selected_event_kind").change(function () {
    const selectedOption = $(".selected_event_kind").val();
    const videoID = $(".selected_event_kind").data("video-id");

    if (selectedOption === "key_incident") {
      $.ajax({
        url: `/video_centre/${videoID}/key_incidents`,
        type: "GET",
      });
    } else if (selectedOption === "competency") {
      $.ajax({
        url: `/video_centre/${videoID}/competencies`,
        type: "GET",
      });
    } else if (selectedOption === "caution") {
      $.ajax({
        url: `/video_centre/${videoID}/cautions`,
        type: "GET",
      });
    }
  });

  $("#video_centre_customer_id_eq").change(function () {
    customerID = $("#video_centre_customer_id_eq").val();

    if (customerID) {
      window.filter_form.showCompetitions(customerID);
      window.filter_form.showSeasons(customerID);
    } else {
      $("#q_competition_id_eq").find("option").remove();

      $("#q_competition_id_eq").append(`<option value=""></option>`);

      $("#q_season_id_eq").find("option").remove();

      $("#q_season_id_eq").append(`<option value=""></option>`);
    }
  });
});
