// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  // On change customer select on fixture form
  $("#fixture_customer_id").on("change", function () {
    var customerId = $(this).val();
    var fixtureCompetitionId = $("#fixture_competition_id");
    var fixtureRoundId = $("#fixture_round_id");
    var fixtureHomeClubId = $("#fixture_home_club_id");
    var fixtureAwayClubId = $("#fixture_away_club_id");

    if (customerId) {
      $.ajax({
        method: "PUT",
        url: "/fixtures/update_competitions_and_clubs",
        data: { customer_id: customerId },
        dataType: "json",
      }).done(function (result) {
        fixtureRoundId.find("option").remove();
        fixtureCompetitionId.find("option").remove();
        fixtureHomeClubId.find("option").remove();
        fixtureAwayClubId.find("option").remove();

        fixtureCompetitionId.append(`<option value=""></option>`);
        $.each(result.competitions, function (index, competition) {
          fixtureCompetitionId.append(
            `<option value="${competition.id}">${competition.name}</option>`
          );
        });

        fixtureHomeClubId.append(`<option value=""></option>`);
        fixtureAwayClubId.append(`<option value=""></option>`);
        $.each(result.clubs, function (index, club) {
          fixtureHomeClubId.append(
            `<option value="${club.id}">${club.name}</option>`
          );
          fixtureAwayClubId.append(
            `<option value="${club.id}">${club.name}</option>`
          );
        });

        fixtureCompetitionId.prop("disabled", false);
        fixtureHomeClubId.prop("disabled", false);
        fixtureAwayClubId.prop("disabled", false);
      });
    } else {
      fixtureRoundId.find("option").remove();
      fixtureCompetitionId.find("option").remove();
      fixtureHomeClubId.find("option").remove();
      fixtureAwayClubId.find("option").remove();

      fixtureRoundId.prop("disabled", true);
      fixtureCompetitionId.prop("disabled", true);
      fixtureHomeClubId.prop("disabled", true);
      fixtureAwayClubId.prop("disabled", true);
    }
  });

  // On change competition select on fixture form
  $("#fixture_competition_id").on("change", function () {
    var competitionId = $(this).val();
    var fixtureRoundId = $("#fixture_round_id");

    if (competitionId) {
      $.ajax({
        method: "PUT",
        url: "/fixtures/update_rounds",
        data: { competition_id: competitionId },
        dataType: "json",
      }).done(function (result) {
        fixtureRoundId.find("option").remove();

        fixtureRoundId.append(`<option value=""></option>`);
        $.each(result.rounds, function (index, round) {
          fixtureRoundId.append(
            `<option value="${round.id}">${round.name}</option>`
          );
        });

        fixtureRoundId.prop("disabled", false);
      });
    } else {
      fixtureRoundId.find("option").remove();

      fixtureRoundId.prop("disabled", true);
    }
  });
});
